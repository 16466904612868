import React, { Component } from 'react';

class App extends Component {
  render() {
    return ( 
        <div>
          Not Authorized
        </div>
    );
  }
}
export default App;